<template>
  <div class="exhibits maximised squares">
    <div class="menu">
      <header class="header"></header>
      <main class="main">
        <section class="section" />
        <section class="section expanded">
          <article
            class="grid"
            ref="grid"
            :style="'--grid-columns: ' + columns + '; --grid-rows: ' + rows"
          />
        </section>
        <section class="section" />
        <section class="section" />
      </main>
    </div>
    <div class="stats">
      <ul>
        <li>{{ w }} x {{ h }}</li>
        <li>&nbsp;</li>
        <li>
          <strong>Columns: {{ columns }}</strong>
        </li>
        <li>
          <strong>Rows: {{ rows }}</strong>
        </li>
        <li>&nbsp;</li>
        <li>Width: {{ gW }}</li>
        <li>Height: {{ gH }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef, no-unused-vars */
export default {
  data() {
    return {
      w: 0,
      h: 0,
      gW: 0,
      gH: 0,
      columns: 0,
      rows: 0,
    };
  },
  methods: {
    resize() {
      let goal = 1;
      let w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let gW = this.$refs.grid.clientWidth;
      let gH = this.$refs.grid.clientHeight;
      let el = new Array(7);
      let cols = 2;

      if (w >= 1152) {
        cols = 3;
        if (w >= 1920) {
          cols = 4;
        }
      }

      cols = 3;

      let sqW = gW / cols;
      let sqH;

      for (var r = 1; r <= 7; r++) {
        sqH = gH / r;
        el[r - 1] = sqW / sqH;
      }

      const closest = el.reduce(function (prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });

      const rows = el.indexOf(closest) + 1;
      //console.log(closest);
      //console.log(rows);

      this.w = w;
      this.h = h;
      this.gW = gW;
      this.gH = gH;
      this.columns = cols;
      this.rows = rows;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
};
</script>

<style>
.stats {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.stats ul {
  list-style-type: none;
  padding: 1em;
}

.squares {
  background: none;
}

.squares .section:not(.expanded) {
  display: none;
}

.squares .expanded {
  position: relative;
}

.squares .section .grid {
  display: grid;
  width: 100%;
  background-color: #eee;
  --grid-cell-height: calc(100% / var(--grid-rows));
  --grid-cell-width: calc(100% / var(--grid-columns));
  background-image: linear-gradient(
      to right,
      var(--primary-color) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, var(--primary-color) 1px, transparent 1px);
  background-repeat: repeat;
  background-position: -1px -1px;
  background-size: var(--grid-cell-width) var(--grid-cell-height);
}

@media only screen and (max-width: 768px) {
  .squares .section {
    padding-top: 9.6rem;
  }
  .squares .section .grid {
    height: calc(100vh - 19.2rem) !important;
  }
}

@media only screen and (min-width: 769px) {
  .stats {
    top: 0;
    left: 10rem;
    bottom: initial;
    right: initial;
    border-left: 1px solid black;
  }
  .squares section article {
    display: grid;
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 9.6rem;
    border-top: 1px solid black;
  }

  .squares .section:not(.expanded) {
    display: block;
  }
}
</style>
